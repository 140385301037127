// Colors
$white		:#FFFFFF;
$black		:#000000;
$gray		:#818285;
$blue		:#0074bd;
$sky-blue	:#17A5C2;
$light-bg	:#F3F9FB;
$turquoise  :#09a8c5;



$search-blue  : #89a1c3;
$lightest-blue: #f4f9fc;

// Elements by color

$accordion-background     : $lightest-blue;
$accordion-section-header : $turquoise;


// Font Size
$x-big-font		:38px;
$big-font		:32px;
$medium-font	:26px;
$small-font		:22px;
$x-small-font	:19px;
$tiny-font		:16.5px;
$x-tiny-font	:14px;

// Width
$large-width    : 1200px;
$width-fluid	: 100%;

// Media Screen
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 320px;
// Small tablets (portrait view)
$screen-md-max: 767px;
// Mobile menu
$screen-mobile: 767px;
// Tablets and small desktops
$screen-lg-max: 991px;
// Large tablets and desktops
$screen-xl-max: 1199px;

// Shadow
$shadow-default	:rgba(0,0,0, .9);