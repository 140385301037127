.not-front .container {
  width: 980px;
  @include lg {
    width: 100%;
  }
}

nav.tabs {
  @include md {
    padding: 10px;
    &:before {
      height: 100%;
    }
    ul.tabs {
      margin-bottom: 0;
      padding: 0;
      li {
        margin-bottom: 2px;
        width: 100%;
        a {
          border: 0;
          border-radius: 0;
          padding: 5px 10px;
        }
      }
    }
  }
}

.block-views-news-block {
  width: 980px;
  margin: 0px auto;
  @include lg {
    width: 100%;
  }
}

.views-display-setting .label {
  color: green;
  border: 1px solid pink;
}

.l-content-news {
  display: block;
  clear: both;
}

.l-content  {
  clear: both;
  .block-hero-no-image {
    background-color: initial;
  }
  .block-hero {
    width: 48%;
    margin: 0 1%;
    height: 550px;
    margin-bottom: 70px;
  }
  .referring-professionals-wrapper {
    background-image: url("/files/hero/1.jpg");
    background-size: cover;

  }
  .learners-wrapper {
    background-image: url("/files/hero/2.jpg");
    background-size: cover;
  }
}

.l-wrapper .l-content .learners-wrapper {
  float: left;
}

.l-wrapper .l-content .referring-professionals-wrapper {
  float: right;
}

input.form-autocomplete,
input.form-text,
input.form-tel,
input.form-email, input.form-url,
input.form-color, input.form-number,
input.form-search, input.form-file, textarea.form-textarea {
  border: 1px solid $gray;
}

.referring-professionals-wrapper,
.learners-wrapper {
  left: 0;
  position: relative;
  a {
    width: 300px;
    position: absolute;
      bottom: 20px;
      left: 50%;
    margin-left: -150px;
  }

}

.not-front .l-top {
  padding-top: 50px;
}

