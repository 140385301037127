// Reset for accordion theming

.panel,
.panel-heading,
.panel h3{
  margin: 0;
  padding: 0;
  border: unset;
  background-color: unset;
  border-radius: unset;
  box-shadow: unset;
}

// Accordion theming

.panel > .panel-heading {
  background-color: unset;
  padding: 0;
  border-bottom: 1px solid $gray;
}

.panel .panel-title a {
  display: block;
  padding: 20px;
}

.panel .panel {
  .panel-body   {
    padding-left: 50px;
  }
  .panel-title a {
    display: block;
    padding-left: 50px;
  }
  .panel-heading {
    padding: 0;
  }

}

.panel:first-child {
  border-top: 1px solid $gray;
}

.panel-default.show-nested > .panel-heading {
  border-bottom: none;
  background-color: $accordion-background;
}

.panel.show-nested a {
  color: $turquoise;
}

.view-id-classes {
  .panel-default.show-parent > .panel-heading {
    border-bottom: none;
    background-color: $accordion-section-header;
    color: white;
  }
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  background-color: $accordion-background;
  border-bottom: 1px solid $gray;
  padding: 0px 38px 20px;
}

.not-nested .panel-default > .panel-heading + .panel-collapse > .panel-body {
  padding: 0px 38px 20px;
  margin-top: -5px;
}

.pathways .panel-default > .nested.panel-heading + .panel-collapse > .panel-body {
  margin-top: -5px;
  padding: 0px 0px 20px 66px;
}

h4.panel-title {
  font-weight: 900;
  font-size: 18px;
}

.panel-body {
  padding: 15px 22px;

}

.show-parent a.accordion-toggle:before,
.show-nested a.accordion-toggle:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f0d7";
  font-weight: 900;
}

.hide-parent a.accordion-toggle:before,
.hide-nested a.accordion-toggle:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f0da";
  font-weight: 900;
}



