.front-news .views-field-title {
  font-size: 22px;
  margin: 10px 0;
  font-weight: bold;
}

// Views block on new pages

.view-display-id-block_1 {
  .views-row {
    padding: 20px 20px 20px;
    border-top: 1px solid $gray;
    clear: both;
  }
  //.views-row:last-of-type {
  //  border-bottom: 1px solid $gray;
  //}
}

.block-views-news-block-1 {
  .views-field-title {
    color: $turquoise;
    font-size: 18px;
    margin: 0;
    font-weight: bold;
  }
  .views-field-created {
    font-size: 16.5px;
    margin-top: -2px;
  }
  .views-field-field-news-body {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 16.5px;
  }
}

.pager-first,
.pager-previous,
.pager-ellipsis,
.pager-last,
.pager-item,
.pager-current,
.pager-next {
  background: white;
  color: grey;
}

.pager-current a,
.pager-next a {
  color: black;
}

.pager li>a, .pager li>span {
  display: inline-block;
  padding: 5px 14px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 15px;
  color: black;
}