.sub-arrow {
  display: none;
}

// Menu

.menu-dropdown {
  ul {
    border: 0 solid;
    box-shadow: 3px 3px 6px -4px rgba(129,130,133,1);
  }
}

header.l-header{
	.l-header-inner{
		.block-system-main-menu {
			clear: unset;
			display: inline-block;
			float: right;
			@include lg {
				float: none;
				width: 100%;
			}
			@include md {
				float: none;
			}
			@include m-menu {
				float: right;
				margin: 0;
				position: absolute;
				right: 0;
				width: 100%;
			}
			.menu-toggle-button {
				line-height: 20px;
				@include md {
					display: block;
					position: absolute;
					top: 30px;
					right: 0;
				}
			}
			.menu-toggle-button-icon {
				background: $white;
				&:before,
				&:after {
					background: $white;
				}
			}
			.menu-toggle-button-text {
				color: $white;
			}
			.menu {
				@include lg {
					display: inline-block;
					margin-bottom: 15px;
				}
				@include md {
					background: $blue;
					display: none;
					top: 90px;
				}
				li {
					margin-top: 45px;
					@include xl {
						margin-top: 25px;
					}
					@include lg {
						margin-top: 0;
					}
					@include md {
						border-bottom: 1px solid #bbbbbb;
						width: 100%;
					}
					a {
						font-size: $tiny-font;
						padding: 15px;
						.sub-arrow {
							right: 3px;
							@include md {
							    height: auto;
								line-height: 20px;
								padding: 5px 15px 10px;
								width: auto;
							}
						}
						@include xl {
							letter-spacing: -0.5px;
							padding: 10px;
						}
					}
					ul {
						li {
							margin-top: 0;
							width: $width-fluid;
							a {
								background: $white;
								color: $black;
								padding: 5px 15px;
								&:hover {
									background: $gray;
									color: $white;
								}
							}
						}
						@include md {
							border-top: 1px solid #bbbbbb;
							position: initial;
							li a {
								background: #114a75;
								color: $white;
								padding: 10px 15px 10px 30px;
							}
						}
					}
				}
				li.last {
					border-bottom: 0;
				}
			}
			.menu-toggle-state:checked ~ .menu {
				display: block;
			}
		}
	}
}
header.sticky .l-header-inner {
	background: $sky-blue;
	position: fixed;
	@include box-shadow(0px 6px 25px -6px rgba(0,0,0,0.25));
	@include m-menu {
		background: $blue;
	}
}