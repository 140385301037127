.breadcrumb {
  background: none;
  margin-bottom: 50px;
  a {
    color: $gray;
  }
}

.breadcrumb li:after {
  border-color: $gray;
}

.breadcrumb li:after {
  content: '';
  /* position: absolute; */
  top: 50%;
  right: 0;
  width: .5em;
  height: .5em;
  border: 0.0625rem solid $gray;
  border-width: 0.125rem 0.125rem 0 0;
  -webkit-transform: translate(0, -50%) rotate(45deg);
  transform: translate(0, -50%) rotate(45deg);
}

.breadcrumb li a, .breadcrumb li span {
  padding: 0.5em 1em 0.5em .8em;
}

.breadcrumb li:first-child {
  margin-left: 5px;
  padding-left: 0px;
}
