// Calendar

.page .view-calendar {
  h3 {
    margin: 0px;
  }
  .views-row {
    padding: 10px 20px;
  }
  .views-row.odd {
    background-color: $accordion-background;
  }
  .views-field-edit-node a {
    color: white;
  }
  .views-field-field-event-date {
    color: $turquoise;
    font-weight: 900;
  }
  .views-field-title h3 a {
    color: black;
    font-size: 18px;
    margin: 0;
  }
}