h1 {
  font-size: 28px;
  font-weight: 600;
}

h1.page-title {
  margin-bottom: 50px;
}

a {
  color: $turquoise;
}