.not-front .l-footer-inner.container {
  width: 100%;
}

.not-front footer.l-footer {
  margin-top: 60px;
}

.l-footer-inner {
  margin: 0px auto;
  font-size: $x-tiny-font;
}

.footer-region {
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  .block:last-of-type {
    display: inline-block;
    vertical-align: bottom;
  }
}

.page .l-footer .footer-region .block-content p {
  text-align: left;
}

.l-footer {
  line-height: 1.5;
  .l-footer-inner {
    input.form-search {
      padding: 15px;
      margin: 0px;
    }
    .l-thirds-region {
      display: inline-block;
      vertical-align: bottom;
    }
    .block {
      width: 90%;
      padding: 0 5%;
      @include md {
        padding: 0;
        width: 100%;
      }
    }
    .block p {
      color: white;
      line-height: 1.5;
      margin: 0px;
    }
    .menu-top-only {
      ul {
        display: flex;
        flex-direction: column;
        li {
          padding: 0px;
          margin: 0px;
          line-height: 1.5;
          a {
            color: white;
          }
          a:hover {
            text-decoration: none;
            color: $turquoise;
          }
        }
      }
    }
  }
}

.l-footer {
  background: $blue;
}

.page .l-footer {
  padding: 50px 0;
  .block-content {
    p {
      color: $white;
      text-align: center;
      a {
        color: $white;
        &:hover {
          color: $blue;
        }
      }
    }
  }
}

.l-footer {
  border: 0;
  margin: 0;
  padding: 100px 0;
  .footer-search {
    .footer-search-wrapper {
      .form-type-search {
        margin-bottom: 50px;
        position: relative;
        &::after{
          background-image: url("../images/search-icon.png");
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 15px;
          content: "";
          height: 20px;
          left: 15px;
          position: absolute;
          width: 20px;
        }
        .form-search {
          background: $white;
          border: 0;
          max-width: 300px;
          opacity: 0.8;
          padding-left: 45px;
          width: $width-fluid;
          @include md {
            max-width: unset;
          }
        }
        label {
          display: none;
        }
      }
      .form-actions {
        display: none;
      }
    }
  }
  .footer-menu {
    .footer-menu-wrapper {
      .menu-toggle-button {
        display: none;
      }
      ul {
        display: block;
        li {
          line-height: 25px;
          width: $width-fluid;
          a {
            color: $white;
            display: inline;
            font-size: $x-tiny-font;
            padding: 0;
            text-decoration: none;
            &:hover {
              color: $sky-blue;
            }
            &:focus {
              background: none;
            }
          }
        }
      }
    }
  }
  .footer-address {
    .footer-address-wrapper {
      p {
        color: $white;
        font-size: $x-tiny-font;
        line-height: 20px;
        span {

        }
        a {
          color: $white;
          font-size: $x-tiny-font;
          margin-left: 30px;
        }
      }
    }
  }
  .footer-contact {
    color: $white;
    min-height: 350px;
    .block-title {
      font-size: $x-tiny-font	;
    }
    .footer-contact-wrapper {
      font-size: $x-tiny-font;
      .form-item {
        margin-bottom: 15px;
        label {
          display: block;
          font-weight: normal;
          line-height: 20px;
          margin: 0;
          .form-required {
            color: $white;
          }
        }
        input {
          border: 0;
          opacity: 0.8;
          padding: 8px 15px;
        }
        textarea {
          border: 0;
          opacity: 0.8;
          padding: 8px 15px;
        }
      }
      .form-actions {
        input {
          background: $white;
          color: $blue;
          cursor: pointer;
          font-weight: bold;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }
      .captcha {
        background: none;
        border: 0;
        padding-top: 40px;
        .fieldset-legend {
          padding: 10px 0;
          line-height: 20px;
        }
        .fieldset-wrapper {
          padding: 0;
          .fieldset-description {
            line-height: 20px;
            padding: 0;
          }
        }
      }
    }
  }
}

.l-bottom {
  background: $sky-blue;
  color: $white;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 100px;
  padding: 100px 30px;
  text-align: center;
  .front-bottom {
    .block-title {
      font-size: $big-font;
      font-style: italic;
      margin: 0 0 50px;
      @include md {
        font-size: 24px;
      }
    }
    .front-bottom-content {
      h3 {
        font-size: $small-font;
        font-weight: bold;
        margin: 0 auto;
        margin-bottom: 10px;
        max-width: 700px;
        text-align: left;
        width: $width-fluid;
        @include md {
          font-size: 20px;
        }
      }
      ul {
        margin: 0 auto;
        max-width: 700px;
        text-align: left;
        width: $width-fluid;
      }
      h4 {
        margin: 40px 0;
      }
      p {
        color: $white;
        margin: 0;
      }
    }
  }
}

//footer.container {
//  max-width: 100%;
//}
//
.footer-right {
  margin-top: 30px;
}