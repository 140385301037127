body {
	font-size: $tiny-font;
}
.messages {
	margin-top: 100px;
}
.block-hero {
	font-size: $x-small-font;
	@include lg {
		position: unset;
		-webkit-transform: none;
		transform: none;
	}
}
.col-md-6 {
	@include m-menu {
		width: 100%;
	}
}
p {
  color: $black;
}
ul {
	padding-left: 20px;
}
.page {
	h3 {
		font-size: $x-small-font;
		font-weight: bold;
		margin: 20px 0 0;
	}
	p {
		margin: 0 0 15px;
	}
}
body.front .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
	max-width: $width-fluid;
	width: $width-fluid;
}
header.l-header {
	background: $sky-blue;
	margin: 0;
	.l-header-inner {
		max-width: $width-fluid;
		padding: 0;
		left: 0;
		line-height: 0;
		top: 0;
		width: $width-fluid;
		z-index: 9;
		@include lg {
			text-align: center;
		}
		.l-header-custom-wrapper {
			display: inline-block;
			padding-left: 30px;
			padding-right: 30px;
			width: $width-fluid;
			@include lg {
				float: none;
				padding: 0;
			}
		}
		.block-system-header {
			clear: unset;
			display: inline-block;
			float: left;
			@include lg {
				float: none;
			}
			@include md {
				float: left;
			}
		}
	}
	.block-content {
		margin: 0 auto;
		max-width: 1200px;
		width: 100%;
	}
	.header-identity-wrapper {
		background: unset;
		.header-site-name-link {
			background: unset;
			@include m-menu {
				padding: 15px 0;
			}
		}
		.header-logo-wrapper {
			display: contents;
			img {
				height: auto;
				max-width: 200px;
				@include xl {
					max-width: 110px;
				}
				@include lg {
					max-width: 135px;
				}
			}
		}
	}
}
.front header {
	background: unset !important;
	.l-header-inner {
		position: absolute;
	}
}

.front .l-top {
	margin-left: -30px;
	margin-right: -30px;
	.front-bg {
		min-height: 600px;
		padding: 300px 30px 100px;
		text-align: center;
		@include md {
			background-position: left -120px bottom 0px;
		}
		.top-title {
			font-size: $x-big-font;
			font-style: italic;
		}
	}
}
.front .l-messages {
	position: absolute;
	top: 200px;
	width: 90%;
	width: calc(100% - 60px);
	width: -webkit-calc(100% - 60px);
	width: -moz-calc(100% - 60px);
	z-index: 9999;
}
.l-wrapper {
	overflow: hidden;
	.l-content {
		.learners-wrapper,
		.referring-professionals-wrapper {
			p {
				color: $white;
				margin: 0;
				text-decoration: none;
				font-size: 19px;
				font-style: initial;
				a {
					border: 3px solid $white;
					color: $white;
					display: inline-block;
					margin-top: 20px;
					padding: 5px 15px;
				}
			}
		}
		p.spclc-hightlight {
			font-size: $medium-font;
			font-style: italic;
		}
		.learners-wrapper  {
			float: left;
			padding: 0 15px 0 0;
			@include md {
				padding: 0;
				margin: 30px -30px 0;
				max-width: unset;
				min-width: 100%;
				width: auto;
			}
			.learners{
				background-position: center;
				background-size: cover;
				color: $white;
				padding: 30px;
				text-align: center;
				h2 {
					font-size: $big-font;
					font-weight: bold;
					@include xl {
						font-size: $medium-font;
					}
					@include lg {
						margin: 0;
					}
				}
				h3 {
					font-size: $medium-font;
					font-weight: bold;
					@include xl {
						font-size: $small-font;
						margin-top: 0;
					}
					@include lg {
						font-size: $x-small-font;
					}
				}
				h4 {
					font-size: $small-font;
					font-weight: bold;
					@include xl {
						font-size: $x-small-font;
						margin: 0;
					}
					@include lg {
						font-size: $tiny-font;
					}
				}
				ul {
					display: inline-block;
					margin: 0;
					text-align: left;
					width: 100%;
					li {
						float: left;
						margin: 0;
						padding: 5px 30px 5px 0;
						width: 50%;
						@include xl {
							font-size: $tiny-font;
						}
						@include lg {
							padding: 0px 30px 0px 0;
							width: 100%;
						}
					}
				}
				p {
					@include xl {
						font-size: $tiny-font;
						a {
							font-size: $x-small-font;
						}
					}
				}
			}
		}
		.referring-professionals-wrapper {
			float: left;
			padding: 0 0 0 15px;
			@include md {
				background-position: right;
				margin-left: -30px;
				margin-right: -30px;
				max-width: unset;
				padding: 0;
				width: auto;
			}
			.referring-professionals {
				background-position: center;
				background-size: cover;
				color: $white;
				padding: 30px;
				text-align: center;
				@include m-menu {
					height: auto !important;
					padding-bottom: 50px;
				}
				h2 {
					color: $white;
					font-size: $big-font;
					font-weight: bold;
				}
				p {
					color: $white;
					margin: 0;
				}
				//pre {
				//	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
				//	line-height: 1.6;
				//	color: #FFFFFF;
				//	font-size: 19px;
				//	background-color: transparent;
				//	border: none;
				//	word-wrap: normal;
				//	overflow: none;
				//}
			}
		}
		.special-text {

		}
	}
	.l-middle {
		clear: both;
	}
}
.block-layout-hero .block-title {
	color: $white;
}
.big-font {
	font-size: $big-font;
}
.front-news {
	.block-title {
		font-size: $medium-font;
		font-weight: bold;
		margin: 0 0 15px;
		padding: 0 30px;
		@include m-menu {
			padding: 0;
		}
	}
	.front-news-items {
		padding: 0 30px 0 30px;
		@include m-menu {
			padding: 0;
		}
		.views-row {
			border-top: 1px solid $black;
			a {
				color: $black;
				display: block;
				font-size: $x-small-font;
				padding: 15px 0;
				text-decoration: none;
				&:hover {
					color: $sky-blue;
				}
				@include md {
					font-size: $tiny-font;
					padding: 5px 0;
				}
			}
		}
		.views-row.last {
			border-bottom: 1px solid $black;
		}
	}
}
//.cra_achievements {
//	border-bottom: 1px solid $black;
//	border-top: 1px solid $black;
//	margin-top: 50px;
//	padding-bottom: 15px;
//	h3 {
//		font-size: $small-font;
//		margin: 10px 0;
//	}
//	p {
//		color: $gray;
//		margin: 0;
//	}
//}

#member-agencies {
	border-bottom: 1px solid $gray;
	.member-agencies-head {
		background: $white;
		border: 0;
		border-radius: 0;
		border-top: 1px solid $gray;
		color: $sky-blue;
		margin: 0;
		padding: 30px;
		.ui-accordion-header-icon {
			background: none;
			border-color: transparent transparent transparent $sky-blue;
			border-style: solid;
			border-width: 8px 0 8px 15px;
			margin-right: 5px;
			-webkit-transition: all 0.5s ease 0s;
			-moz-transition: all 0.5s ease 0s;
			-o-transition: all 0.5s ease 0s;
			transition: all 0.5s ease 0s;
		}
	}
	.ui-state-active {
		.ui-accordion-header-icon {
			border-color: $sky-blue transparent transparent transparent;
			border-style: solid;
			border-width: 15px 8px 0 8px;
		}
	}
	.ui-accordion-header-active {
		background: $light-bg;
	}
	.member-agencies-body {
		background: $light-bg;
		border: 0;
		border-radius: 0;
		padding-bottom: 15px;
	}
}

.not-front header.l-header {
	background: #17A5C2;
	margin: 0;
	z-index: 100;
	width: 100%;
	position: fixed;
	top: 0px;
	@include md {
		background: $blue;
		border-bottom: 1px solid #FFF;
	}
}

.not-front .l-page-title {
	margin-top: 150px;
}

//.block-hero-image {
//	border: 3px solid pink;
//}

.block-hero-image:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0,0,0, 0.4);
	transition: all .3s linear;
}

.l-top {
	position: relative;
	.block-block-transforming-lives-through-adult-learning {
		position: absolute;
		top: 400px;
		z-index: 5;
		width: 100%;
		@include md {
			top: 250px;
		}
		p {
			font-size: 45px;
			font-style: italic;
			color: #FFFFFF;
			font-weight: 200;
			line-height: 1.2;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			text-align: center;
			@include md {
				font-size: $big-font;
				padding: 0 15px;
			}
		}
	}
}







