// Staff Page

.block-views-staff-consultants-block-1 {
  .block-title {
    margin: 30px 0;
  }
  .views-row:last-child {
    border-bottom: 1px solid grey;
    padding-bottom: 40px;
  }
  .views-row {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    padding: 40px 0 20px;
    border-top: 1px solid $gray;
    .views-field {
      padding: 0px 1%;
    }
    .views-field-field-head-shot {
      width: 18%;
      order: 1;
    }
    .views-field-nothing {
      width: 28%;
      order: 2;
    }
    .views-field-field-staff-quote {
      width: 44%;
      order: 3;
      padding: 0 1.5%;
      color: $turquoise;
    }
    .views-field-edit-node {
      width: 10%;
      order: 4;
    }
    .staff-title {
      font-weight: 900;
    }
    @include lg {
      .staff-email {
        word-break: break-all;
      }
    }
    @include md {
      display: inline-block;
      margin: 0;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100%;
      .views-field {
        float: left;
        margin-bottom: 15px;
        padding: 0;
        width: 100%;
        img {
          width: 100%;
        }
      }
      .views-field-edit-node {
        a {
          border: 1px solid $turquoise;
          display: block;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}

.node-type-page .field-type-image {
  margin-bottom: 48px;
}