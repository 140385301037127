.view-classes .views-field {
  margin-bottom: 15px;
  .class-label {
    color: black;
    font-weight: 900;
  }
}

//.view-member-agencies .views-field {
//  margin-bottom: 5px;
//  .class-label {
//    color: black;
//    font-weight: 900;
//  }
//}